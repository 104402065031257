@import "@/assets/constants/index.scss";
.score-parent {
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  .score-item {
    width: 200px;
    height: 144px;
    background-color: $c-white;
    border-radius: 8px;
    margin-right: 16px;
    border: 1px solid $c-blue-grey3;
    padding-top: 27px;

    .score-header {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      text-align: center;
      font-weight: normal;
      color: $c-grey2;
      margin-bottom: 0;
    }

    .score-value {
      font-size: 48px;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      font-weight: normal;
      color: $c-grey2;
    }
  }
}
